<template>
  <v-col cols="12" md="4">
    <v-card
      hover
      class="mx-auto"
      max-width="400"
      :to="{ name: 'ScientistVsCaveman' }"
    >
      <v-img :src="Scientist2VsCaveman2" contain height="200" />
      <v-card-text class="text--primary text-left">
        <h3 class="mt-2">Scientist vs Caveman Hitting Approach</h3>
        <h5 class="grey-text">April 10th, 2022 - Stone Osborne</h5>
        <p class="mt-2">
          I like to define the hitting appproach as 2 different styles, a
          scientist's approach and caveman's approach. Both will have ...

          <v-btn
            text
            color="blue"
            class="mb-1"
            :to="{ name: 'ScientistVsCaveman' }"
            >READ MORE</v-btn
          >
        </p>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import Scientist2VsCaveman2 from '@/assets/images/Scientist2VsCaveman2.svg'

export default {
  data() {
    return {
      Scientist2VsCaveman2: Scientist2VsCaveman2,
    }
  },
}
</script>

<style>
.grey-text {
  color: grey;
}
</style>
