<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h1 class="mt-4 mb-4">Welcome to the Blog</h1>
      </v-col>
    </v-row>

    <v-row>
      <FirstAnnualT3Holder />
      <ScientistVsCavemanHolder />
      <DoublePlaySSHolder v-show="false" />
    </v-row>
  </v-container>
</template>

<script>
import FirstAnnualT3Holder from '@/components/Blog/FirstAnnualT3Holder.vue'
import ScientistVsCavemanHolder from '@/components/Blog/ScientistVsCavemanHolder.vue'
import DoublePlaySSHolder from '@/components/Blog/DoublePlaySSHolder.vue'

export default {
  components: {
    FirstAnnualT3Holder,
    ScientistVsCavemanHolder,
    DoublePlaySSHolder,
  },
  data() {
    return {}
  },
}
</script>

<style></style>
