<template>
  <v-col cols="12" md="4">
    <v-card
      hover
      class="mx-auto"
      max-width="400"
      :to="{ name: 'FirstAnnualT3' }"
    >
      <v-img
        class="dark align-center"
        :src="TThreeFirstAnnual"
        contain
        height="200"
      />
      <v-card-text class="text--primary text-left">
        <h3 class="mt-2">1st Annual "T3" Spikeball Tournament</h3>
        <h5 class="grey-text">November 13th, 2021 - Stone Osborne</h5>
        <p class="mt-2">
          The 1st Annual "T3" Spikeball Tournament boasted a 14 team open style
          double elimaination tournament that included pool play for....

          <v-btn text color="blue" class="mb-1" :to="{ name: 'FirstAnnualT3' }"
            >READ MORE</v-btn
          >
        </p>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import TThreeFirstAnnual from '@/assets/images/T31stAnnual.svg'
export default {
  data() {
    return {
      TThreeFirstAnnual: TThreeFirstAnnual,
    }
  },
}
</script>

<style>
.grey-text {
  color: grey;
}
</style>
